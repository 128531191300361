import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import FormSelect from 'ui-kit/form-select/form-select';

import {
    DrugEditFormFieldsEnum,
    DrugForm,
    DrugFormOptions,
    DrugName,
    DrugQuantity,
    DrugStrength
} from 'types/discount-card';

import { noop } from 'util/function';

import './drug-search-form.styles.scss';

interface DrugSearchFormProps {
    drug?: DrugFormOptions;
    onChange: (fieldValue: string, fieldType: DrugEditFormFieldsEnum) => void;
}

const DrugSearchForm = ({ drug, onChange }: DrugSearchFormProps) => {
    const { t } = useTranslation();

    const { drugOptions, formOptions, quantityOptions, strengthOptions } = drug as DrugFormOptions;

    const formatDrugName = ({ DrugName, BrandGeneric, IsSelected }: DrugName) => {
        const formatted = `${DrugName} (${BrandGeneric})`;
        return { key: formatted, label: formatted, value: `${DrugName}&&${BrandGeneric}`, IsSelected };
    };

    const formatQuantity = ({ Quantity, QuantityLabel, IsSelected, GSN }: DrugQuantity) => {
        const formatted = `${Quantity} ${QuantityLabel}`;
        return { key: formatted, label: formatted, value: `${Quantity}-${GSN}`, IsSelected };
    };

    const formatForm = ({ Form, GSN, IsSelected }: DrugForm) => {
        return { key: Form, label: Form, value: GSN, IsSelected };
    };

    const formatStrength = ({ Strength, GSN, IsSelected }: DrugStrength) => {
        return { key: Strength, label: Strength, value: GSN, IsSelected };
    };

    const formattedQuantities = quantityOptions.map(formatQuantity);
    const formattedForms = formOptions.map(formatForm);
    const formattedStrengths = strengthOptions.map(formatStrength);
    const formattedDrugName = drugOptions.map(formatDrugName);

    return (
        <Formik
            onSubmit={noop}
            initialValues={{
                brand: formattedDrugName.find((f) => f.IsSelected)?.value,
                form: formattedForms.find((f) => f.IsSelected)?.value,
                dosage: formattedStrengths.find((f) => f.IsSelected)?.value,
                quantity: formattedQuantities.find((f) => f.IsSelected)?.value
            }}
            enableReinitialize
        >
            {({ initialValues, values, touched }) => {
                const handleChangeFilter = (select: { key: string; option: any }, form = null, field: any) => {
                    onChange(select.option.value, field.name as DrugEditFormFieldsEnum);
                };

                return (
                    <Form id="drug-search-form" autoComplete="off" className="drug-search-form">
                        <Field
                            id="drug-search-brand"
                            name="brand"
                            options={formattedDrugName}
                            component={FormSelect}
                            value={values.brand}
                            touched={touched.brand}
                            initialValues={initialValues.brand}
                            placeholder={t('pages.discountCard.pharmacyResults.drugForm.placerholder.brand')}
                            disabled={formattedDrugName.length <= 1}
                            onSelectionChanged={handleChangeFilter}
                        />
                        <Field
                            id="drug-search-form"
                            name="form"
                            options={formattedForms}
                            component={FormSelect}
                            value={values.form}
                            touched={touched.form}
                            initialValues={initialValues.form}
                            placeholder={t('pages.discountCard.pharmacyResults.drugForm.placerholder.form')}
                            disabled={formattedForms.length <= 1}
                            onSelectionChanged={handleChangeFilter}
                        />
                        <Field
                            id="drug-search-dosage"
                            name="dosage"
                            options={formattedStrengths}
                            component={FormSelect}
                            value={values.dosage}
                            touched={touched.dosage}
                            initialValues={initialValues.dosage}
                            placeholder={t('pages.discountCard.pharmacyResults.drugForm.placerholder.dosage')}
                            disabled={formattedStrengths.length <= 1}
                            onSelectionChanged={handleChangeFilter}
                        />
                        <Field
                            id="drug-search-quantity"
                            name="quantity"
                            options={formattedQuantities}
                            component={FormSelect}
                            value={values.quantity}
                            touched={touched.quantity}
                            initialValues={initialValues.quantity}
                            placeholder={t('pages.discountCard.pharmacyResults.drugForm.placerholder.quantity')}
                            disabled={formattedQuantities.length <= 1}
                            onSelectionChanged={handleChangeFilter}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
};

export default DrugSearchForm;
