import { WebMercatorViewport } from '@math.gl/web-mercator';
import { Dispatch, SetStateAction } from 'react';

import { PharmacyInfo, PharmacyResults } from 'types/discount-card';
import { FormattedLocation, GeoJSONFeatureCollection, Location } from 'types/mapbox';

export function createGeoJSON(geocodedAddresses: FormattedLocation[]): GeoJSONFeatureCollection {
    return {
        type: 'FeatureCollection',
        features: geocodedAddresses
            .filter((item) => item.id !== undefined)
            .map((item) => ({
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: item.coordinates
                },
                properties: {
                    id: item.id!,
                    name: item.name,
                    address: item.address,
                    price: item.price
                }
            }))
    };
}

export function formatLocationData(pharmacy: PharmacyInfo): FormattedLocation {
    const formattedLocation: FormattedLocation = {
        id: pharmacy.NCPDP,
        name: pharmacy.PharmacyName,
        coordinates: [pharmacy.Longitude, pharmacy.Latitude],
        address: `${pharmacy.Address}, ${pharmacy.City}, ${pharmacy.State} ${pharmacy.Zip}`,
        price: pharmacy.price
    };

    if (pharmacy.Distance) {
        formattedLocation.distance = pharmacy.Distance;
    }

    return formattedLocation;
}

export function translateToAddressesData(pharmacies: PharmacyResults[]) {
    if (!pharmacies) return;

    const addressesData: FormattedLocation[] = [];
    pharmacies.forEach((pharmacy) => {
        pharmacy.locations.forEach((location) => {
            addressesData.push(formatLocationData(location));
        });
    });

    return addressesData;
}

export function setBounds(
    geoJSON?: GeoJSONFeatureCollection,
    setLat?: Dispatch<SetStateAction<number | null>>,
    setLng?: Dispatch<SetStateAction<number | null>>,
    setZoom?: Dispatch<SetStateAction<number | null>>
) {
    // calculate the bounding box of all markers and fit the map to the bounding box
    if (geoJSON && geoJSON.features?.length > 0) {
        const coordinates = geoJSON?.features.map((feature) => feature.geometry.coordinates);

        // Calculate the bounding box
        const bounds = coordinates.reduce(
            (acc, coord) => {
                return {
                    minLng: Math.min(acc.minLng, coord[0]),
                    maxLng: Math.max(acc.maxLng, coord[0]),
                    minLat: Math.min(acc.minLat, coord[1]),
                    maxLat: Math.max(acc.maxLat, coord[1])
                };
            },
            {
                minLng: Infinity,
                maxLng: -Infinity,
                minLat: Infinity,
                maxLat: -Infinity
            }
        );

        const viewport = new WebMercatorViewport().fitBounds(
            [
                [bounds.minLng, bounds.minLat],
                [bounds.maxLng, bounds.maxLat]
            ],
            {
                padding: 30,
                width: 650,
                height: 785
            }
        );
        setLat?.(viewport.latitude);
        setLng?.(viewport.longitude);
        setZoom?.(viewport.zoom);
    }
}
