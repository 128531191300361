const MapViewIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="120" height="121" viewBox="0 0 120 121" fill="none">
            <path
                d="M1.78809 60.4999C1.78809 103.46 48.2938 130.31 85.4983 108.83C102.765 98.8612 113.402 80.4378 113.402 60.4999C113.402 17.5398 66.8961 -9.31027 29.6915 12.1698C12.4248 22.1387 1.78809 40.5621 1.78809 60.4999Z"
                fill="white"
            />
            <path
                d="M35.1899 105.526C35.1899 107.218 49.8392 108.275 61.5587 107.429C66.9976 107.037 70.3482 106.311 70.3482 105.526C70.3482 103.835 55.6989 102.778 43.9795 103.623C38.5405 104.016 35.1899 104.741 35.1899 105.526Z"
                fill="#D6E7FF"
            />
            <path
                d="M16.1455 55.7188C16.1295 83.9113 46.6387 101.549 71.0622 87.4667C82.4101 80.9236 89.3994 68.8179 89.392 55.7188C89.408 27.5263 58.8987 9.88849 34.4752 23.9708C23.1273 30.514 16.138 42.6197 16.1455 55.7188Z"
                fill="white"
            />
            <path
                d="M83.2421 76.0324C78.2557 73.1123 70.0661 67.374 59.255 56.5643L59.2228 56.5308C58.3286 57.4613 57.409 58.4072 56.4325 59.381C55.382 60.4316 54.3592 61.4278 53.3631 62.3849C36.5681 78.4475 26.873 81.6159 26.873 81.6159C40.9455 95.6935 63.6856 95.9531 78.0757 82.2005C78.0522 82.2144 78.0302 82.2308 78.0102 82.2493C79.9782 80.3764 81.7329 78.2914 83.2421 76.0324Z"
                fill="white"
            />
            <path
                d="M72.4617 24.8477C71.2171 27.7776 66.1653 37.9303 50.5756 53.5212C34.986 69.1122 24.825 74.1726 21.8965 75.4114C23.3261 77.6534 24.9948 79.7337 26.8731 81.6158C28.7515 83.4978 37.7555 78.0595 56.4326 59.3809C75.1097 40.7024 78.6604 29.8243 78.6604 29.8243C76.7801 27.9463 74.7017 26.2775 72.4617 24.8477Z"
                fill="#97D3B7"
            />
            <path
                d="M72.4617 24.8477C71.2171 27.7776 66.1653 37.9303 50.5756 53.5212C34.986 69.1122 24.825 74.1726 21.8965 75.4114C23.3261 77.6534 24.9948 79.7337 26.8731 81.6158C28.7515 83.4978 37.7555 78.0595 56.4326 59.3809C75.1097 40.7024 78.6604 29.8243 78.6604 29.8243C76.7801 27.9463 74.7017 26.2775 72.4617 24.8477Z"
                stroke="#222A3F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.1455 55.7188C16.1295 83.9113 46.6387 101.549 71.0622 87.4667C82.4101 80.9236 89.3994 68.8179 89.392 55.7188C89.408 27.5263 58.8987 9.88849 34.4752 23.9708C23.1273 30.514 16.138 42.6197 16.1455 55.7188Z"
                stroke="#222A3F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M23.4702 49.1267C23.4702 51.1498 27.1326 55.719 27.1326 55.719C27.1326 55.719 30.7948 51.1498 30.7948 49.1267C30.7948 46.3075 27.743 44.5455 25.3014 45.9551C24.1683 46.6092 23.4702 47.8183 23.4702 49.1267Z"
                fill="#5CC4ED"
                stroke="#222A3F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M51.3042 27.8851C51.3042 29.908 54.9666 34.4773 54.9666 34.4773C54.9666 34.4773 58.6288 29.908 58.6288 27.8851C58.6288 25.0658 55.577 23.3038 53.1354 24.7134C52.0023 25.3676 51.3042 26.5766 51.3042 27.8851Z"
                fill="#5CC4ED"
                stroke="#222A3F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M27.1328 24.9554C27.1328 26.9783 30.7952 31.5475 30.7952 31.5475C30.7952 31.5475 34.4574 26.9783 34.4574 24.9554C34.4574 22.1362 31.4055 20.3741 28.964 21.7837C27.8309 22.4379 27.1328 23.6469 27.1328 24.9554Z"
                fill="#5CC4ED"
                stroke="#222A3F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M61.4526 52.5295C61.4526 59.2835 71.6374 74.237 75.0416 79.0378C75.5744 79.7878 76.688 79.7878 77.2208 79.0378C80.6209 74.237 90.8098 59.2835 90.8098 52.5295C90.8098 41.23 78.5777 34.1678 68.792 39.8176C64.2504 42.4396 61.4526 47.2854 61.4526 52.5295Z"
                fill="white"
                stroke="#222A3F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M69.4595 52.5292C69.4648 57.6651 75.028 60.8692 79.473 58.2965C83.9181 55.7241 83.9115 49.3042 79.461 46.7409C78.4482 46.1576 77.2998 45.8506 76.1312 45.8506C72.4436 45.8503 69.4557 48.8417 69.4595 52.5292Z"
                fill="#F2F9FC"
                stroke="#222A3F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M116.023 104.892C118.795 107.663 117.527 112.395 113.741 113.41C111.984 113.881 110.109 113.378 108.823 112.092L95.3203 98.5882L102.518 91.3877L116.023 104.892Z"
                fill="#5CC4ED"
            />
            <path
                d="M116.023 104.892C118.795 107.663 117.527 112.395 113.741 113.41C111.984 113.881 110.109 113.378 108.823 112.092L95.3203 98.5882L102.518 91.3877L116.023 104.892Z"
                stroke="#222A3F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M88.1182 87.7883L91.7175 84.188L100.721 93.1889L97.1215 96.7892L88.1182 87.7883Z"
                fill="white"
                stroke="#222A3F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M92.6206 56.2811C105.095 68.7545 99.3878 90.054 82.3486 94.6199C65.3092 99.186 49.7168 83.5942 54.2821 66.5547C55.3232 62.6689 57.369 59.1256 60.2136 56.2811C69.1635 47.3347 83.6706 47.3347 92.6206 56.2811Z"
                fill="#5CC4ED"
                stroke="#222A3F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M88.1184 60.7791C97.1265 69.7879 93.0043 85.1698 80.6985 88.4667C68.3927 91.7636 57.1326 80.5027 60.4302 68.197C61.1821 65.3914 62.6592 62.8331 64.713 60.7791C71.1751 54.3132 81.6562 54.3132 88.1184 60.7791Z"
                fill="white"
            />
            <path
                d="M88.1184 60.7791C97.1265 69.7879 93.0043 85.1698 80.6985 88.4667C68.3927 91.7636 57.1326 80.5027 60.4302 68.197C61.1821 65.3914 62.6592 62.8331 64.713 60.7791C71.1751 54.3132 81.6562 54.3132 88.1184 60.7791Z"
                stroke="#222A3F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M88.1184 60.7793C81.6553 54.3158 71.1761 54.3158 64.713 60.7793C64.5401 60.9523 64.3727 61.1295 64.208 61.3081C67.3731 67.4757 70.9943 73.3984 75.0415 79.0267C75.5743 79.7767 76.6879 79.7767 77.2208 79.0267C81.3582 73.2794 85.0432 67.2197 88.2427 60.902C88.2022 60.87 88.1603 60.8253 88.1184 60.7793Z"
                fill="white"
            />
            <path
                d="M88.1184 60.7793C81.6553 54.3158 71.1761 54.3158 64.713 60.7793C64.5401 60.9523 64.3727 61.1295 64.208 61.3081C67.3731 67.4757 70.9943 73.3984 75.0415 79.0267C75.5743 79.7767 76.6879 79.7767 77.2208 79.0267C81.3582 73.2794 85.0432 67.2197 88.2427 60.902C88.2022 60.87 88.1603 60.8253 88.1184 60.7793Z"
                stroke="#222A3F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default MapViewIcon;
