/* eslint-disable no-unused-vars */
export interface DiscountCardProps {
    memberId: string;
    bin: string;
    pcn: string;
    group: string;
}

export interface SearchResultsProps {
    selectedPharmacy?: PharmacyInfo;
    pharmacies?: PharmacyResults[];
    onSelectedPharmacy?: (pharmacy?: PharmacyInfo) => void;
}

export interface DrugInfoProps {
    genericName?: DrugInfo['GenericName'];
    brandName?: DrugInfo['BrandName'];
    dosage?: string;
    quantity?: string;
    location?: string;
    image?: string;
    description?: string;
    ndc?: string;
    brandGeneric?: string;
}
export interface PrescriptionFormProps {
    drugInfo?: DrugInfoProps;
    drugForm?: DrugFormOptions;
    showModal?: boolean;
    onPrescriptionChange: (fieldValue: string, fieldType: DrugEditFormFieldsEnum) => void;
    onMoreDrugInfoClick: () => void;
}

export type PharmacyListProps = SearchResultsProps;

export type PharmacyResults = {
    name: string;
    price: number | string;
    address?: string;
    resultsNumber?: number;
    locations: Array<PharmacyInfo>;
    pharmacy?: Omit<PharmacyResults, 'onLocationClick' | 'onSelectedPharmacy'>;
    isLowestPrice?: boolean;
    onLocationClick?: () => void;
    onSelectedPharmacy?: (pharmacy: object | undefined) => void;
};

export interface PharmacyInfo extends Drug {
    name: string;
    address: string;
    schedules: string;
    price?: number;
    phoneNumber?: string;
    isLowestPrice?: boolean;
    isSelected?: boolean;
    onCardClick?: (info: PharmacyInfo) => void;
}

// Props to be defined after maps integration
export interface UserLocation {
    address?: USPSPartialAddress;
    location?: unknown;
}

export interface LocationFilters {
    radiusRange: number | undefined;
}

export interface USPSPartialAddress {
    city: string | undefined;
    state: string | undefined;
    zip: string | undefined;
}
// Props to be defined after maps integration
export interface SearchPrescriptionPayload {
    drugName: string;
    location: unknown;
}
export interface SearchPrescriptionResponse {
    Data: SearchPrescriptionPayload;
    Errors: string[];
    Success: boolean;
    Message?: string;
}

export interface ScriptSaveAuthTokenResponse {
    tokenType: string;
    expiresIn: number;
    extExpiresIn: number;
    accessToken: string;
}

export interface ScriptSaveAutocompleteResponse {
    DrugNames: Record<'DrugName', string>[];
}

export interface ScriptSaveGetAutocompleteValuesPayload {
    search: string;
    count: number;
    authToken: string;
}
export interface searchDrugsByNameParams {
    drugName?: string;
    lat?: string;
    lng?: string;
    zipCode?: string;
    brandIndicator?: string;
    GSN?: string;
    includeDrugInfo?: boolean;
    includeDrugImage?: boolean;
    qty?: string;
    numPharm?: string;
    useUC?: boolean;
    priceOnly?: boolean;
    referencedBN?: string;
}

export interface Drug {
    ChainCode: string;
    PharmacyName: string;
    Latitude: number;
    Longitude: number;
    Address: string;
    Phone: string;
    Distance: number;
    City: string;
    State: string;
    Zip: string;
    HoursOfOperation: string;
    GroupName: string;
    NDC: string;
    LN: string;
    Qty: number;
    Price: number;
    PriceBasis: string;
    UCPrice: number;
    AWPPrice: number | null;
    MACPrice: number | null;
    BrandGeneric: string;
    DrugRanking: number;
    QuantityRanking: number;
    NCPDP: string;
    GroupNum: string;
    GSN: string;
    Splittable: boolean;
}

export enum DrugEditFormFieldsEnum {
    // eslint-disable-next-line no-unused-vars
    Brand = 'brand',
    // eslint-disable-next-line no-unused-vars
    Form = 'form',
    // eslint-disable-next-line no-unused-vars
    Dosage = 'dosage',
    // eslint-disable-next-line no-unused-vars
    Quantity = 'quantity'
}

export interface DrugName {
    DrugName: string;
    BrandGeneric: string;
    IsSelected: boolean;
}

export interface DrugQuantity {
    GSN: string;
    Quantity: number;
    QuantityLabel: string;
    Ranking: number;
    IsSelected: boolean;
}

export interface DrugForm {
    GSN: string;
    Form: string;
    Ranking: number;
    IsSelected: boolean;
    LN: string | null;
    CommonQty: number;
    IsDiscontinued: boolean;
    DiscontinuedDate: string | null;
}

export interface DrugStrength {
    GSN: string;
    Strength: string;
    Ranking: number;
    IsSelected: boolean;
    LN: string | null;
    CommonQty: number;
}

export interface DrugInfo {
    GenericName: string;
    BrandName: string;
    Description: string;
    GSN: string;
    NDC: string;
    PillImage: string;
    PillImageLastUpdated: string;
}

export interface DrugSearchResponse {
    Drugs: Drug[];
    Names: DrugName[];
    Quantities: DrugQuantity[];
    Forms: DrugForm[];
    Strengths: DrugStrength[];
    DrugInfo?: DrugInfo;
}

export interface DrugFormOptions {
    drugOptions: DrugSearchResponse['Names'];
    formOptions: DrugSearchResponse['Forms'];
    strengthOptions: DrugSearchResponse['Strengths'];
    quantityOptions: DrugSearchResponse['Quantities'];
}

export interface SendSmsEmailCardPayload {
    communicationAddress: string;
    name: string;
    memberId: string;
    bin: string;
    pcn: string;
    group: string;
}

export interface DiscountCardResponse {
    groupID: string;
    cardholderID: string;
    rxBIN: string;
    rxPCN: string;
}

export interface DrugFormStrengthResponse {
    DrugName: string;
    BrandGeneric: string;
    Forms: DrugForm[];
    Strengths: DrugStrength[];
    Quantities: DrugQuantity[];
}

export function mapPharmacyResultsToPharmacyInfo(pharmacyResults: PharmacyResults): PharmacyInfo {
    const { onSelectedPharmacy } = pharmacyResults;

    return {
        // Mapping properties from Drug
        ChainCode: pharmacyResults.locations?.[0]?.ChainCode || '',
        PharmacyName: pharmacyResults.name,
        Latitude: pharmacyResults.locations?.[0]?.Latitude || 0,
        Longitude: pharmacyResults.locations?.[0]?.Longitude || 0,
        Address: pharmacyResults.address || '',
        Phone: pharmacyResults.locations?.[0]?.Phone || '',
        Distance: pharmacyResults.locations?.[0]?.Distance || 0,
        City: pharmacyResults.locations?.[0]?.City || '',
        State: pharmacyResults.locations?.[0]?.State || '',
        Zip: pharmacyResults.locations?.[0]?.Zip || '',
        HoursOfOperation: pharmacyResults.locations?.[0]?.HoursOfOperation || '',
        GroupName: pharmacyResults.locations?.[0]?.GroupName || '',
        NDC: pharmacyResults.locations?.[0]?.NDC || '',
        LN: pharmacyResults.locations?.[0]?.LN || '',
        Qty: pharmacyResults.locations?.[0]?.Qty || 0,
        Price: pharmacyResults.price as number,
        PriceBasis: pharmacyResults.locations?.[0]?.PriceBasis || '',
        UCPrice: pharmacyResults.locations?.[0]?.UCPrice || 0,
        AWPPrice: pharmacyResults.locations?.[0]?.AWPPrice || null,
        MACPrice: pharmacyResults.locations?.[0]?.MACPrice || null,
        BrandGeneric: pharmacyResults.locations?.[0]?.BrandGeneric || '',
        DrugRanking: pharmacyResults.locations?.[0]?.DrugRanking || 0,
        QuantityRanking: pharmacyResults.locations?.[0]?.QuantityRanking || 0,
        NCPDP: pharmacyResults.locations?.[0]?.NCPDP || '',
        GroupNum: pharmacyResults.locations?.[0]?.GroupNum || '',
        GSN: pharmacyResults.locations?.[0]?.GSN || '',
        Splittable: pharmacyResults.locations?.[0]?.Splittable || false,

        name: pharmacyResults.name,
        address: pharmacyResults.address || '',
        schedules: pharmacyResults.locations[0]?.HoursOfOperation || '',
        price: pharmacyResults.price ? (pharmacyResults.price as number) : undefined,
        phoneNumber: pharmacyResults.locations[0]?.Phone,
        isLowestPrice: pharmacyResults.isLowestPrice,

        onCardClick: onSelectedPharmacy ? () => onSelectedPharmacy(pharmacyResults) : undefined
    };
}

export function mapPharmacyInfoToPharmacyResults(pharmacyInfo: PharmacyInfo): PharmacyResults {
    return {
        name: pharmacyInfo.PharmacyName || pharmacyInfo.name,
        price: pharmacyInfo.price || pharmacyInfo.Price,
        address: pharmacyInfo.Address || pharmacyInfo.address,
        locations: [
            {
                ChainCode: pharmacyInfo.ChainCode,
                PharmacyName: pharmacyInfo.PharmacyName,
                Latitude: pharmacyInfo.Latitude,
                Longitude: pharmacyInfo.Longitude,
                Address: pharmacyInfo.Address,
                Phone: pharmacyInfo.Phone,
                Distance: pharmacyInfo.Distance,
                City: pharmacyInfo.City,
                State: pharmacyInfo.State,
                Zip: pharmacyInfo.Zip,
                HoursOfOperation: pharmacyInfo.HoursOfOperation,
                GroupName: pharmacyInfo.GroupName,
                NDC: pharmacyInfo.NDC,
                LN: pharmacyInfo.LN,
                Qty: pharmacyInfo.Qty,
                Price: pharmacyInfo.Price,
                PriceBasis: pharmacyInfo.PriceBasis,
                UCPrice: pharmacyInfo.UCPrice,
                AWPPrice: pharmacyInfo.AWPPrice,
                MACPrice: pharmacyInfo.MACPrice,
                BrandGeneric: pharmacyInfo.BrandGeneric,
                DrugRanking: pharmacyInfo.DrugRanking,
                QuantityRanking: pharmacyInfo.QuantityRanking,
                NCPDP: pharmacyInfo.NCPDP,
                GroupNum: pharmacyInfo.GroupNum,
                GSN: pharmacyInfo.GSN,
                Splittable: pharmacyInfo.Splittable,

                // Map additional PharmacyInfo properties
                name: pharmacyInfo.name,
                address: pharmacyInfo.address,
                schedules: pharmacyInfo.schedules,
                phoneNumber: pharmacyInfo.phoneNumber,
                isLowestPrice: pharmacyInfo.isLowestPrice,
                isSelected: pharmacyInfo.isSelected,
                onCardClick: pharmacyInfo.onCardClick
            }
        ],
        pharmacy: undefined,
        isLowestPrice: pharmacyInfo.isLowestPrice
    };
}
