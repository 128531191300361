import React from 'react';

function NoDrugsFound() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.0336 10.1834L14.2177 14.358L7.38672 8.11467L11.2026 3.93924C11.6106 3.4835 12.105 3.11317 12.6571 2.84969C13.2091 2.58621 13.808 2.4348 14.4189 2.40422C15.0299 2.37364 15.6409 2.4645 16.2165 2.67154C16.7921 2.87858 17.321 3.19769 17.7725 3.61041C18.224 4.02314 18.5892 4.52129 18.847 5.07605C19.1048 5.63081 19.25 6.23118 19.2743 6.84243C19.2986 7.45367 19.2014 8.06366 18.9884 8.63713C18.7755 9.21059 18.4509 9.73613 18.0336 10.1834Z"
                fill="#D6E7FF"
                stroke="#222A3F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.4076 18.5328L14.2179 14.3605L7.38692 8.11719L3.57344 12.2926C3.16371 12.7413 2.84637 13.2664 2.63955 13.8378C2.43273 14.4092 2.34047 15.0157 2.36805 15.6227C2.42375 16.8487 2.96417 18.0023 3.87044 18.8298C4.77671 19.6573 5.97458 20.0909 7.20054 20.0352C8.42649 19.9795 9.58011 19.439 10.4076 18.5328Z"
                fill="#5CC4ED"
                stroke="#222A3F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.5487 19.7498C12.4291 20.6301 13.5507 21.2297 14.7718 21.4726C15.9928 21.7155 17.2585 21.5908 18.4088 21.1144C19.559 20.638 20.5421 19.8311 21.2338 18.796C21.9255 17.7608 22.2947 16.5437 22.2947 15.2987C22.2947 14.0537 21.9255 12.8367 21.2338 11.8015C20.5421 10.7663 19.559 9.95949 18.4088 9.48305C17.2585 9.00662 15.9928 8.88197 14.7718 9.12488C13.5507 9.36778 12.4291 9.96732 11.5487 10.8477C10.3683 12.0282 9.70508 13.6293 9.70508 15.2987C9.70508 16.9682 10.3683 18.5693 11.5487 19.7498Z"
                fill="#F2F9FC"
                stroke="#222A3F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.3847 19.6855L11.6133 10.9141"
                stroke="#222A3F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default NoDrugsFound;
