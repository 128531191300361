import { useTranslation } from 'react-i18next';
import sanitizeHtml from 'sanitize-html';

import './modal-contents.styles.scss';

interface DrugInfoModalProps {
    description: HTMLBodyElement;
}

const DrugInfoModal = ({ description }: DrugInfoModalProps) => {
    const { t } = useTranslation();

    return (
        <div className="modal-drug-info">
            <div className="modal-drug-info-header">
                <div className="half-pill"></div>
                <h6 className="title">{t('pages.discountCard.pharmacyResults.prescriptionForm.link')}</h6>
            </div>
            <div
                className="modal-drug-info-content"
                dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(description.innerHTML, {
                        allowedAttributes: {
                            ...sanitizeHtml.defaults.allowedAttributes,
                            '*': ['class']
                        }
                    })
                }}
            />
        </div>
    );
};

export default DrugInfoModal;
