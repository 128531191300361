// PharmacyList.tsx
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { PharmacyInfo, PharmacyListProps, PharmacyResults } from 'types/discount-card';

import PharmacyDetails from '../pharmacy-details';
import './pharmacy-list.styles.scss';

const findLowestPricePharmacy = (pharmacies?: PharmacyResults[]) => {
    if (!pharmacies || pharmacies?.length === 0) return null;

    return pharmacies?.reduce((lowest, pharmacy) => {
        const currentPrice = parseFloat(pharmacy?.price as string);
        if (lowest === null || currentPrice < parseFloat(lowest.price as string)) {
            return pharmacy;
        }
        return lowest;
    }, null as PharmacyResults | null);
};

const PharmacyList: React.FC<PharmacyListProps> = ({ pharmacies, selectedPharmacy, onSelectedPharmacy }) => {
    const [selectedCardId, setSelectedCardId] = useState<string | null>(null);
    const [openGroupId, setOpenGroupId] = useState<string | null>(null);
    const lowestPricePharmacy = findLowestPricePharmacy(pharmacies);

    const pharmacyRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

    const handleGroupToggle = useMemo(
        () => (groupId: string) => {
            setOpenGroupId((prevOpenGroupId) => (prevOpenGroupId === groupId ? null : groupId));
        },
        []
    );

    const handleCardClick = useCallback(
        (pharmacy: PharmacyInfo) => {
            if (selectedCardId !== pharmacy.NCPDP) {
                setSelectedCardId(pharmacy.NCPDP);
                onSelectedPharmacy?.(pharmacy);
            } else {
                setSelectedCardId(null);
                onSelectedPharmacy?.(undefined);
            }
        },
        [selectedCardId, onSelectedPharmacy]
    );

    useEffect(() => {
        if (selectedPharmacy && selectedPharmacy?.NCPDP !== selectedCardId) {
            setSelectedCardId(selectedPharmacy.NCPDP);
            setOpenGroupId(selectedPharmacy.name);

            setTimeout(() => {
                pharmacyRefs.current[selectedPharmacy.name]?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }, 100);
        }
    }, [selectedCardId, selectedPharmacy]);

    useEffect(() => {
        if (!selectedPharmacy) setSelectedCardId(null);
    }, [selectedPharmacy]);

    return (
        <div className="pharmacy-list">
            {pharmacies?.map((pharmacy, index) => (
                <div key={index} ref={(el) => (pharmacyRefs.current[pharmacy.name] = el)}>
                    <PharmacyDetails
                        key={index}
                        name={pharmacy?.name}
                        price={pharmacy?.price}
                        resultsNumber={pharmacy?.resultsNumber}
                        pharmacy={pharmacy}
                        locations={pharmacy?.locations}
                        isLowestPrice={lowestPricePharmacy !== null && pharmacy === lowestPricePharmacy}
                        onSelectedPharmacy={(pharmacy) => handleCardClick(pharmacy as PharmacyInfo)}
                        selectedCardId={selectedCardId}
                        isOpen={openGroupId === pharmacy.name}
                        onGroupToggle={() => handleGroupToggle(pharmacy.name)}
                    />
                </div>
            ))}
        </div>
    );
};

export default PharmacyList;
