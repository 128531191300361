import classNames from 'classnames';
import React, { ChangeEvent } from 'react';

import { RadioButtonProps } from './radio-button.props';
import './radio-button.styles.scss';

const RadioButtonComponent = (props: RadioButtonProps) => {
    const mainClass = classNames('radio-button-component', { boxed: props.variation === 'boxed' });
    const labelClass = classNames('radio-button-component--label', { highlight: props.isSelected });

    const handleLabelClick = () => {
        const radioInput = document.getElementById(props.inputName) as HTMLInputElement;
        if (radioInput) {
            radioInput.click();
        }
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        props.onChange(event);
    };

    return (
        <div className={mainClass}>
            <label className={labelClass} htmlFor={`${props.inputName}-${props.value}`} onClick={handleLabelClick}>
                <input
                    type="radio"
                    className="radio-button-component--input"
                    id={`${props.inputName}-${props.value}`}
                    name={props.inputName}
                    value={props.value}
                    onChange={handleChange}
                    checked={props.isSelected}
                />
                {props?.label}
            </label>
        </div>
    );
};

export default RadioButtonComponent;
