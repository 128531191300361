import React, { CSSProperties, ReactElement } from 'react';

const PhoneIcon = ({ className, style }: { className?: string; style?: CSSProperties }): ReactElement => {
    return (
        <div className={`drop-files-icon ${className ?? ''}`} style={style}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Phone" clipPath="url(#clip0_5478_15319)">
                    <path
                        id="Vector"
                        d="M9.03477 22.1059C7.99478 22.7768 6.75549 23.0692 5.52526 22.934C4.29506 22.7986 3.14894 22.2439 2.27967 21.3628L1.51972 20.6197C1.18655 20.2789 1 19.8213 1 19.3448C1 18.8682 1.18655 18.4104 1.51972 18.0698L4.74528 14.8779C5.08322 14.5458 5.53806 14.3598 6.01187 14.3598C6.48566 14.3598 6.94052 14.5458 7.27844 14.8779C7.61922 15.2111 8.07689 15.3977 8.55348 15.3977C9.03007 15.3977 9.48772 15.2111 9.8285 14.8779L14.8948 9.81161C15.0638 9.64505 15.1981 9.44656 15.2897 9.22768C15.3813 9.00879 15.4284 8.77387 15.4284 8.53659C15.4284 8.29929 15.3813 8.06439 15.2897 7.84549C15.1981 7.6266 15.0638 7.42811 14.8948 7.26155C14.5627 6.92363 14.3766 6.46878 14.3766 5.99498C14.3766 5.52117 14.5627 5.06633 14.8948 4.72839L18.1035 1.51972C18.4443 1.18654 18.9019 1 19.3785 1C19.8552 1 20.3128 1.18654 20.6536 1.51972L21.3967 2.27967C22.2775 3.14894 22.8323 4.29506 22.9677 5.52526C23.1031 6.75549 22.8106 7.99478 22.1396 9.03477C18.6438 14.1868 14.1958 18.6233 9.03477 22.1059Z"
                        stroke="#1A497F"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_5478_15319">
                        <rect width="24" height="24" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

export default PhoneIcon;
