import { Form, Formik, FormikHelpers } from 'formik';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import Button from 'ui-kit/button/button';
import Text from 'ui-kit/text/text';
import ToastBox from 'ui-kit/toast-box/toast-box';

import AddressBox from 'components/address-box';
import { ModalComponentContent, ModalComponentFooter } from 'components/modal/modal.component';
import PrescriptionSearch from 'components/prescription-search';

import { DiscountCardErrors } from 'state/discount-card/discount-card.helpers';
import { discountCardSearchRoutine, discountCardSetLocationRoutine } from 'state/discount-card/discount-card.routines';
import {
    discountCardErrorSelector,
    discountCardIsLoadingSelector,
    discountCardUserLocationAddressSelector,
    discountCardUserLocationSelector
} from 'state/discount-card/discount-card.selector';
import { closeModalComponent, openModalComponent } from 'state/modal/modal.reducer';

import { searchDrugsByNameParams } from 'types/discount-card';

import './modal-contents.styles.scss';
import UpdateLocationContent from './update-location.component';

interface SearchAgainProps {
    handleCloseModal: () => void;
}

const SearchAgain = ({ handleCloseModal }: SearchAgainProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const isLoading = useSelector(discountCardIsLoadingSelector);
    const userLocationAddress = useSelector(discountCardUserLocationAddressSelector);
    const discountCardError = useSelector(discountCardErrorSelector);
    const hasLocationError = discountCardError && discountCardError.messageText === DiscountCardErrors.locationNotFound;
    const hasLocation = userLocationAddress !== undefined;
    const zipCode = useSelector(discountCardUserLocationSelector);

    const prescriptionSearchRef = useRef<any>(null);

    const handleSubmitSearch = useCallback(
        (drugName: string) => {
            if (!hasLocation) {
                dispatch(discountCardSearchRoutine.failure({ messageText: DiscountCardErrors.locationNotFound }));
                return;
            }

            const searchPayload: searchDrugsByNameParams = {
                drugName,
                zipCode: zipCode?.address?.zip
            };

            dispatch(discountCardSearchRoutine.trigger({ data: searchPayload }));
            dispatch(closeModalComponent());
        },
        [hasLocation]
    );

    const handleChangeAddress = () => {
        dispatch(
            openModalComponent({
                title: t('pages.discountCard.updateLocation.modal.title'),
                hasDefaultFooter: false,
                hasCustomContent: true,
                content: <UpdateLocationContent handleCloseModal={() => dispatch(closeModalComponent())} />,
                variation: 'small',
                isCentered: true,
                onClose: () => dispatch(closeModalComponent())
            })
        );
    };

    const triggerSearch = () => {
        if (prescriptionSearchRef.current) {
            prescriptionSearchRef.current.triggerSearch();
        }
    };

    return (
        <ModalComponentContent>
            <div className="modal-search-again">
                <>
                    <PrescriptionSearch
                        ref={prescriptionSearchRef}
                        onSearch={handleSubmitSearch}
                        isBusy={isLoading}
                        withButton={false}
                    />
                </>
            </div>
            <ModalComponentFooter
                continueButtonLabel={t('pages.discountCard.pharmacyResults.modal.button')}
                cancelButtonLabel={t('pages.discountCard.pharmacyResults.modal.cancelButton')}
                onCancel={handleCloseModal}
                onContinue={triggerSearch}
            />
        </ModalComponentContent>
    );
};

export default SearchAgain;
