import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'ui-kit/button/button';
import LocationIcon from 'ui-kit/icons/location-icon/location-icon';

import UpdateLocationContent from 'display-components/birdi-discount/modal-contents/update-location.component';

import { formatLocationAddress } from 'state/discount-card/discount-card.helpers';
import { discountCardUserLocationSelector } from 'state/discount-card/discount-card.selector';
import { closeModalComponent, openModalComponent } from 'state/modal/modal.reducer';

import './current-location.styles.scss';

const CurrentLocation = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const locationSelector = useSelector(discountCardUserLocationSelector);
    const formatAddress = formatLocationAddress(locationSelector?.address, true);

    const handleUpdateLocation = () => {
        dispatch(
            openModalComponent({
                title: t('pages.discountCard.updateLocation.modal.title'),
                hasDefaultFooter: false,
                hasCustomContent: true,
                content: (
                    <UpdateLocationContent hasDispatchSearch handleCloseModal={() => dispatch(closeModalComponent())} />
                ),
                variation: 'small',
                isCentered: true,
                onClose: () => dispatch(closeModalComponent())
            })
        );
    };

    return (
        <div className="current-location">
            {locationSelector && (
                <>
                    <div className="current-location--address">
                        <LocationIcon colorHex={'#686D7B'} />
                        <p className="current-location--text-label">{formatAddress}</p>
                    </div>

                    <Button
                        label={t('pages.discountCard.pharmacyResults.searchResults.actions.locationBtn')}
                        type="button"
                        variant="text-blue"
                        className="no-padding"
                        onClick={handleUpdateLocation}
                    />
                </>
            )}
        </div>
    );
};

export default CurrentLocation;
