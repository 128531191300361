import { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import RadioButtonComponent from 'ui-kit/radio-button/radio-button';

import { ModalComponentContent, ModalComponentFooter } from 'components/modal/modal.component';

import { setLocationRadius } from 'state/discount-card/discount-card.reducers';
import { discountCardLocationRadiusSelector } from 'state/discount-card/discount-card.selector';
import { closeModalComponent } from 'state/modal/modal.reducer';

import './modal-contents.styles.scss';

const ChangeRadiusContent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const radiusSelector = useSelector(discountCardLocationRadiusSelector);

    const [selectedValue, setSelectedValue] = useState(radiusSelector);

    const radiusOptions = [
        {
            value: 1,
            label: '1 mile'
        },
        {
            value: 5,
            label: '5 miles'
        },
        {
            value: 10,
            label: '10 miles'
        },
        {
            value: 25,
            label: '25 miles'
        },
        {
            value: 50,
            label: '50 miles'
        }
    ];

    const handleRadioChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const value = Number(event.target.value);
            setSelectedValue(value);
        },
        [setSelectedValue]
    );

    const handleSelectRadius = useCallback(() => {
        dispatch(
            setLocationRadius({
                radiusRange: selectedValue
            })
        );
        dispatch(closeModalComponent());
    }, [dispatch, selectedValue]);

    const handleCancel = () => {
        dispatch(closeModalComponent());
    };

    return (
        <ModalComponentContent>
            <div className="modal-change-radius">
                <p>{t('pages.discountCard.pharmacyResults.searchResults.radiusLocation.modal.copy')}</p>
                <div className="modal-change-radius--options">
                    {radiusOptions.map((radio) => (
                        <RadioButtonComponent
                            key={radio.value}
                            inputName="radius"
                            variation="boxed"
                            isSelected={selectedValue === radio.value}
                            onChange={handleRadioChange}
                            value={radio.value}
                            label={radio.label}
                        />
                    ))}
                </div>
            </div>
            <ModalComponentFooter
                continueButtonLabel={t(
                    'pages.discountCard.pharmacyResults.searchResults.radiusLocation.modal.ctaContinue'
                )}
                cancelButtonLabel={t('pages.discountCard.pharmacyResults.searchResults.radiusLocation.modal.ctaCancel')}
                onCancel={handleCancel}
                onContinue={handleSelectRadius}
            />
        </ModalComponentContent>
    );
};

export default ChangeRadiusContent;
