import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'ui-kit/button/button';

import ChangeRadiusContent from 'display-components/birdi-discount/modal-contents/change-radius.component';

import { discountCardLocationRadiusSelector } from 'state/discount-card/discount-card.selector';
import { closeModalComponent, openModalComponent } from 'state/modal/modal.reducer';

import './distance-radius.styles.scss';

const DistanceRadius = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const radiusSelector = useSelector(discountCardLocationRadiusSelector);

    const handleChangeRadius = () => {
        dispatch(
            openModalComponent({
                title: t('pages.discountCard.pharmacyResults.searchResults.radiusLocation.modal.title'),
                hasCustomContent: true,
                hasDefaultFooter: false,
                content: <ChangeRadiusContent />,
                variation: 'small',
                isCentered: true,
                onClose: () => dispatch(closeModalComponent())
            })
        );
    };

    return (
        <div className="distance-radius">
            <p className="distance-radius--text-label">
                {t('pages.discountCard.pharmacyResults.searchResults.radiusLocation.miles', {
                    miles: radiusSelector,
                    hasMoreThenOne: radiusSelector && radiusSelector > 1 ? 's' : ''
                })}
            </p>
            <Button
                label={t('pages.discountCard.pharmacyResults.searchResults.actions.distanceBtn')}
                type="button"
                variant="text-blue"
                className="distance-radius--no-padding"
                onClick={handleChangeRadius}
            />
        </div>
    );
};

export default DistanceRadius;
